import React from 'react';
import Layout from '../components/layout/Layout';
import { graphql, Link } from "gatsby";
import Img from 'gatsby-image';
import './recipes.scss';

const RecipeLinkCard = ({ recipe }) => (
  <div className="recipe-card col-12 col-lg-4">
    <Link to={recipe.frontmatter.path}>
      <Img sizes={recipe.frontmatter.featureImage.childImageSharp.sizes}/>
      <h4 className={"mt-2"}>{recipe.frontmatter.title}</h4>
    </Link>
  </div>
);

const RecipesPage = ({ location, data }) => (
  <Layout location={location}>
    <div className="row justify-content-lg-center">
      <div className="col col-lg-8">
        <h3>Recipes</h3>
        <p className={'lead'}>
          Here are some recipes I already made with my students. Feel free to ask me for any recipe you would like to
          cook and I will add the description here (after Cooking Class).
        </p>
        <div className="container">
          <div className="row justify-content-lg-center">
            {data.allMarkdownRemark.edges.map(({ node }, index) => {
              return <RecipeLinkCard key={index} recipe={node}/>;
            })}
          </div>
        </div>

      </div>
    </div>
  </Layout>
);

export const pageQuery = graphql`
  query {
    allMarkdownRemark (filter: {frontmatter: {category: {eq: "recipe"}}}){
      edges {
        node {
          frontmatter {
            path
            title
            category
            featureImage {
              childImageSharp{
                sizes(maxWidth: 1000, maxHeight:1000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default RecipesPage;
